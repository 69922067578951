<template>
  <component :is="companyData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="companyData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching company data
      </h4>
      <div class="alert-body">
        No company found with this company id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          Company List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <!-- <b-tabs
      v-if="companyData"
      pills
    > -->

    <!-- Tab: Account -->
    <b-tab active>
      <div class="p-2">
        <!-- Media -->
        <b-media class="mb-2">
          <template #aside>
            <b-img
              ref="refCompanyImage"
              :src="
                companyData.company_image_url
                  ? `${companyData.company_image_url}`
                  : require('@/assets/images/placeholder/no-image.jpg')
              "
              class="float-left"
              rounded
              style="width: 120px; height: 120px"
            />
          </template>
          <h4 class="mb-1">
            {{ companyData.official_name }}
          </h4>
          <div class="d-flex flex-wrap">
            <!-- <b-button
              variant="primary"
              @click="$refs.refInputEl.click()"
            >
              <input
                ref="refInputEl"
                type="file"
                class="d-none"
                accept="image/*"
                @change="updateCurrImg"
              >
              <span class="d-none d-sm-inline">Update Image</span>
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
            </b-button> -->
            <div
              style="width: 250px;height: 50px;"
              class="mb-2"
            >
              <file-pond
                v-if="showCompanyImagePond"
                ref="pond-company"
                name="company-profile-pic-upload"
                label-idle="Upload a new image."
                accepted-file-types="image/jpeg, image/png"
                :server="companyProfileServer"
                :files="companyProfileFiles"
                credits="false"
                @init="handleCompanyProfileImagePondInit"
              />
            </div>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="removeCompanyImage"
            >
              <span class="d-none d-sm-inline">Remove Image</span>
              <feather-icon
                icon="TrashIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
          </div>
        </b-media>

        <!-- User Info: Input Fields -->
        <b-row>
          <!-- Field: original_name -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Official Company Name"
              label-for="official_name"
            >
              <b-form-input
                id="official_name"
                v-model="companyData.company_name"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: UEN -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="UEN"
              label-for="uen_no"
            >
              <b-form-input
                id="uen_no"
                v-model="companyData.uen_no"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Company Summary -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Company Summary"
              label-for="company_summary"
            >
              <b-form-textarea
                id="company_summary"
                v-model="companyData.company_summary"
                rows="6"
                class="w-full"
                placeholder="Describe your company here"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Action Buttons -->
        <div class="d-flex flex-row-reverse my-2">
          <b-button
            class="mx-1 view-company"
            variant="primary"
            @click="viewCompany"
          >View Company</b-button>

          <b-button
            variant="warning"
            class="mx-1 click-save"
            @click="put_company"
          >Save Profile</b-button>
        </div>
      </div>
    </b-tab>
    <!-- </b-tabs> -->
  </component>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BImg,
  BLink,
  BMedia,
  BRow,
  BTab,
  BTabs,
  BTable,
} from 'bootstrap-vue'
import { pick } from 'lodash'
import vSelect from 'vue-select'

import vueFilePond, { setOptions } from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { singleUpload, deleteObjectByKey } from '@/components/awsS3'
import CompanyEditTabAccount from './CompanyEditTabAccount.vue'
import CompanyEditTabInformation from './CompanyEditTabInformation.vue'
import CompanyEditTabSocial from './CompanyEditTabSocial.vue'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,

)

export default {
  components: {
    BAlert,
    BAvatar,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BImg,
    BLink,
    BMedia,
    BRow,
    BTab,
    BTabs,
    BTable,
    vSelect,
    FilePond,
    CompanyEditTabAccount,
    CompanyEditTabInformation,
    CompanyEditTabSocial,
  },
  data() {
    return {
      isLoading: false,
      companyData: [],

      companyProfileServer: {},
      companyProfileFiles: null,
      showCompanyImagePond: true,
    }
  },
  created() {
    // this.companyData = {
    //   id: 1,
    //   uen_no: 1,
    //   avatar: 'https://api.headhuntershq.com/api/media/HeadHunters HQ!4599299.png',
    //   official_company_name: 'HeadHunters HQ',
    //   postal_code: 12345678,
    //   primary_ssic: 123456,
    //   secondary_ssic: 123456,
    //   created_on: new Date(),
    //   email: 'test@email.com',
    // }

    this.get_company()
  },
  methods: {
    handleCompanyProfileImagePondInit() {
      const { company_id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-company']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()
            singleUpload(file, 'company-profile-images', `${company_id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },

    get_company() {
      const { token } = this.$store.state.auth.ActiveUser
      // const { company_name } = this.$store.state.auth.ActiveUser
      const { id } = this.$route.params

      this.isLoading = true
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.companyData = {}

      this.$http
        .get(`/api/companies/${id}`)
        .then(response => {
          this.companyData = response.data
          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', 'Could not retrieve the company details. Please try again later!')

          this.isLoading = false
        })
    },
    put_company(redirect=true) {
      const proceed = true

      if (proceed) {
        const { token } = this.$store.state.auth.ActiveUser
        const { id } = this.$route.params

        const form_data = pick(this.companyData, [
          'id',
          'official_name',
          'company_summary',
          'company_image',
        ])

        this.$http.defaults.headers.common.Authorization = `Token ${token}`
        this.$http
          .put(`/api/companies`, {
            company_info: form_data,
          })
          .then(response => {
            this.$toastSuccess('Profile Update', 'Company profile has been updated successfully!')

            if (redirect) {
              this.viewCompany()
            }

            this.get_company()
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      } else {
        this.$toastDanger()
      }
    },
    viewCompany() {
      this.$router.push({ name: 'companies-view', params: { id: this.companyData.id } })
    },

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {}

        const outerThis = this
        const img = new Image()
        img.src = window.URL.createObjectURL(input.target.files[0])
        img.onload = () => {
          if (img.height !== 200 || img.width !== 200) {
            outerThis.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Image size error',
                  icon: 'AlertCircleIcon',
                  text: 'The accepted width and height of the image is 400px and 400px respectively.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            return false
          }

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Upload Status',
                icon: 'AlertCircleIcon',
                text: 'Uploading profile image in progress.',
                variant: 'warning',
                progress: 0,
                progressId: 'uploadImage',
              },
            },
            { timeout: this.$fileUploadTimeout },
          )

          const fd = new FormData()
          fd.append('file', input.target.files[0])
          fd.append('company_id', this.$route.params.id)
          const url = '/api/upload-company-pic'

          this.$refs.refInputEl.value = null
          this.$http
            .post(url, fd, {
              onUploadProgress(progressEvent) {
                const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                const parent = document.querySelector('#uploadImage')
                parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
              },
            })
            .then(response => {
              if (response.data.success) {
                // this.accountInfo.avatar = response.data.image
                this.$refs.refCompanyImage.src = ''
                this.$refs.refCompanyImage.src = response.data.image

                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Company Image Update',
                      icon: 'AlertCircleIcon',
                      text: response.data.message,
                      variant: 'success',
                    },
                  },
                )
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Profile Update',
                      icon: 'AlertCircleIcon',
                      text: response.data.message,
                      variant: 'danger',
                    },
                  },
                )
              }

              this.isLoading = false
            })
            .catch(error => {
              this.$handleErrorResponse(error)
            })

          const { token } = this.$store.state.auth.ActiveUser
          this.$http.defaults.headers.common.Authorization = `Token ${token}`
          reader.readAsDataURL(input.target.files[0])
        }
      }
    },

    removeCompanyImage() {
      const outerThis = this
      deleteObjectByKey(this.companyData.company_image, outerThis, true)

      this.$set(this.companyData, 'company_image', null)
      this.showCompanyImagePond = false
      setTimeout(() => {
        this.showCompanyImagePond = true
      }, 100)
      this.put_company(false)
    },

  },
}
</script>

<style>

</style>
